<template>
    <TheOrganizationAddSpace :newSpaceType="spaceTypes.EDUCATION_SPACE" spaceTypeIcon="school"></TheOrganizationAddSpace>
</template>

<script>
import { enumsData } from '@/mixins/enums'
const TheOrganizationAddSpace = () => import('../components/TheHomeAddSpace')

export default {
    mixins: [enumsData],
    components: {
        TheOrganizationAddSpace
    }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TheOrganizationAddSpace", {
    attrs: {
      newSpaceType: _vm.spaceTypes.EDUCATION_SPACE,
      spaceTypeIcon: "school"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }